import { dom, library } from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown as farAngleDown,
    faAngleUp as farAngleUp,
    faArrowUpRightFromSquare,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faEnvelope,
    faLock,
    faPrint,
    faXmark
} from '@fortawesome/pro-regular-svg-icons'
import {
    faAngleDown as fasAngleDown,
    faAngleUp as fasAngleUp,
    faMagnifyingGlass as fasMagnifyingGlass,
    faRectangleXmark
} from '@fortawesome/pro-solid-svg-icons'
import { faCalendarPlus, faCircleUser, faMobile } from '@fortawesome/pro-light-svg-icons'
import { faAngleUp as fasrAngleUp } from '@fortawesome/sharp-regular-svg-icons'
import { faChevronRight as fassChevronRight } from '@fortawesome/sharp-solid-svg-icons'

import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faTiktok,
    faVimeoV,
    faXTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(faInstagram, faXTwitter, faFacebookF, faYoutube, faVimeoV, faLinkedinIn, faTiktok, farAngleDown, fasAngleDown, faCircleUser, faEnvelope, faPrint, faMobile, fasMagnifyingGlass, farAngleUp, fasAngleUp, fasrAngleUp, faArrowUpRightFromSquare, faXmark, faCalendarPlus, faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight, faLock, faRectangleXmark, fassChevronRight, faCheck)
// dom.watch()
// const css = dom.css()
// dom.insertCss(css)
dom.i2svg()
dom.watch()
// window.addEventListener('DOMContentLoaded', () => {
//     dom.i2svg()
//     dom.watch()
// })
